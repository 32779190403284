import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  TextField,
  MenuItem,
} from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { CirromClient } from "../api/releases";
import { fetchAuthSession } from "@aws-amplify/auth";

interface Rule {
  category: string;
  description: string;
  probability: number;
  initialChanges: number;
  totalChanges: number;
  enabled: boolean;
}

interface RuleHubProps {
  clients: CirromClient[];
}

export function RuleHub({ clients }: RuleHubProps) {
  const [clientId, setClientId] = useState<number>();
  const [rules, setRules] = useState<Rule[]>([]);

  const columns = [
    { field: "category", headerName: "Category", sortable: true, filter: true },
    {
      field: "description",
      headerName: "Rule Description",
      sortable: true,
      filter: true,
    },
    {
      field: "probability",
      headerName: "Probability",
      sortable: true,
      filter: true,
    },
    {
      field: "initialChanges",
      headerName: "Initial Changes",
      sortable: true,
      filter: true,
    },
    {
      field: "totalChanges",
      headerName: "Total Changes",
      sortable: true,
      filter: true,
    },
    {
      field: "enabled",
      headerName: "Enabled",
      sortable: true,
      filter: true,
      cellRenderer: "agCheckboxCellRenderer",
      cellRendererParams: {
        checkbox: true,
      },
      editable: true,
    },
  ];

  const fetchRules = async (clientId: number) => {};

  return (
    <Card>
      <CardHeader title="Rule Hub" titleTypographyProps={{ variant: "h3" }} />
      <CardContent>
        <TextField
          margin={"dense"}
          size={"small"}
          variant={"standard"}
          label={"Client Id"}
          onChange={(event) => {
            setClientId(parseInt(event.target.value));
          }}
          select
          style={{ width: "250px" }}
        >
          {clients
            .sort((first, second) => {
              if (first.instance_name < second.instance_name) {
                return -1;
              }
              if (first.instance_name > second.instance_name) {
                return 1;
              }
              return 0;
            })
            .map((client) => (
              <MenuItem key={client.id} value={client.id}>
                {client.instance_name}
              </MenuItem>
            ))}
        </TextField>
        <div className="ag-theme-alpine" style={{ height: 600, width: "100%" }}>
          <AgGridReact
            rowData={rules}
            columnDefs={columns}
            defaultColDef={{
              flex: 1,
              minWidth: 100,
              resizable: true,
            }}
            onCellValueChanged={(event) => {
              if (event.colDef.field === "enabled") {
                // Handle the enabled/disabled state change here
                console.log(
                  `Rule ${event.data.description} is now ${
                    event.newValue ? "enabled" : "disabled"
                  }`
                );
                // You might want to call an API to update the rule status
              }
            }}
          />
        </div>
      </CardContent>
    </Card>
  );
}
